.rotate {
  animation: rotateLoop infinite 1s linear;
}

@keyframes rotateLoop {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}